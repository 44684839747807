/* Scroll behaviour to land at section considering the sticky navbar*/
html{
    scroll-padding-top: 65px;
}

/* Fonts*/
@font-face {
    font-family: "Inter-Regular";
    src: local("Inter-Regular"),
     url("../public/assets/fonts/Inter-Regular.ttf") format("truetype");
    font-weight:normal;
}
@font-face {
    font-family: "Inter-Bold";
    src: local("Inter-Bold"),
     url("../public/assets/fonts/Inter-Bold.ttf") format("truetype");
    font-weight:bold;
}

body{
    font-family: "Inter-Regular","Segoe UI";
}

.bold{
    font-family: "Inter-Bold","Segoe UI";
}

/* Modals for email*/
.modal{
    font-family: "Iter-Bold", "Segoe UI";
    font-size:larger;
    color: #008cf4;
}

.modal-content{
    background-color: #f8f8f8;
}

#modal-p{
    padding:5% 5% 10% 3%;
}

#modal-p div p{
    font-size:x-large
}

.modal-header{
    border-bottom: none !important;
}

.btn-clip{
    background-color: #fae39d;
    color: rgba(0, 0, 0, 0.7);
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    border-color: rgba(0, 0, 0, 0.55);
    padding:2% 3% 2% 3%;
    text-decoration: none;
}

.btn-clip span{
    color: #008cf4;
}

.btn-clip:hover{
    background-color: #fae39d;
    color: rgba(0, 0, 0, 0.55);
    border-radius: 5px;
    border-color: #fae39d;
    transform:scale(1.02);
}

.btn-clip:hover span{
    color: rgba(0, 0, 0, 0.55) !important;
}
