/* Hero Section */
.hero{
    background-color: #f8f8f8;
}

.hero-row{
    padding:10% 0% 12.5% 0%;
}

.hero-col{
    padding:5% 0% 1% 8%;
}


.hero-col h1{
    font-size: calc(37px + (47 - 37) * ((100vw - 320px) / (1600 - 320)));
    margin-bottom: calc(20px + (35 - 20) * ((100vw - 320px) / (1600 - 320)));
    padding-right: 20%;
}

.hero-col p{
    font-size: larger;
    padding-right: 30%;
}

.hero-img{
    max-height: 450px;
}

.hello-link{
    display: inline;
    color: #008cf4 !important;
    background-color: #f8f8f8;
    border: none;
}

.hello-link:hover{
    background-color: #f8f8f8;
}

@media (max-width: 1199px){
    .hero-col{
        padding:3% 0% 10% 7%;
    }
    .hero-col h1{
        padding-right: 13%;
    }
    .hero-col p{
        padding-right: 15%;
    }
    .hero-img{
        max-height: 410px;
    }
}

@media (max-width: 991px){
    .hero-row{
        padding-top:5%;
        padding-bottom:0%;
    }
    .hero-col{
        padding-top:6% ;
        padding-bottom:5%;
        padding-left:20%;

    }
    .hero-col h1{
        padding-right: 10%;
    }
    .hero-col p{
        padding-right: 10%;
    }
    .hero-img{
        max-height: 430px;
    }
}

@media (max-width: 767px) and (min-width: 576px){
    .hero-col{
        padding:6% 0% 5% 10%;
    }
    .hero-col h1{
        padding-right: 0%;
    }
    .hero-col p{
        padding-right: 5%;
    }
}

@media (max-width: 576px){
    .hero-col{
        padding:3% 0% 5% 15% !important;
    }
    .hero-col p{
        padding-right: 10% !important;
    }
    .hero-img{
        max-height: 350px;
        margin-bottom: 7%;
    }
}
@media (max-width: 432px){
    .hero-img{
        max-height: 320px;
    }
}

/* Section Colours */
.section-first{
    background-color: #eaeaea;
}

.section-second{
    background-color: #f8f8f8;
}

/* Sections style and layout */
.row-sections{
    padding:13% 0% 15% 0%;
}

.col-sections p{
    margin-bottom: 4%;
}

.col-sections-odd{
    margin-left: 5%;
}

.col-sections-even p{
    margin-right: 7%;
}

.first{
    order: 1;
}

.last{
    order: 2;
}

@media (min-width: 1400px){
    .first-img{
        max-width: 550px;
    }
    .sections-img{
        max-width: 550px;
    }
}

@media (max-width: 1399px){
    .first-img{
        max-width: 600px;
    }    
}

@media (max-width: 1200px){
    .first-img{
        max-width: 450px;
    }    
}

@media (max-width: 991px){
    .col-sections{
        margin-bottom: 7%;
    }
    .col-sections-even{
        margin-left: 5%;
    }
    .row-sections{
        padding-bottom:15%;
    }
    .row-sections div p{
        padding-right: 5%;
    }
    .first-img {
        max-width: 600px;
    }
    .sections-img {
        max-width: 600px;
    }
}

@media (max-width: 767px){
    .row-sections{
        padding-bottom:22%;
    }
    .col-sections{
        flex-shrink: none !important;
    }
    .col-sections-even, .col-sections-odd{
        margin-left: 0%;
    }
    
}

@media (max-width: 694px){
    .first-img {
        max-width: 500px;
    }
    .sections-img {
        max-width: 500px;
    }
}

@media (max-width: 594px){
    .col-sections{
        flex-shrink: 1 !important;
    }
    .first-img {
        max-width: 450px;
    }
    .sections-img {
        max-width: 450px;
    }
}

@media (max-width: 576px){
    .col-sections{
        margin: 7% 5% 12% 10% !important;
    }
    
}
@media (max-width: 540px){
    .first-img {
        max-width: 400px;
    }
    .sections-img {
        max-width: 400px;
    }
}

@media (max-width: 420px){
    .first-img {
        max-width: 300px;
    }
    .sections-img {
        max-width: 300px;
    }
}
/* All links*/
.home-link{
    display: inline;
    color: #008cf4 !important;
    transition: none;
}

.home-link:hover{
    color: #4064ac !important;
    transition: none;
}

.home-link .expand{
    color: rgba(0, 0, 0, 0.7);
}

.home-link:hover .expand{
    transition: none;
    transform: scale(1.1);
}
.img-link{
    transition: none;
}
.img-link:hover{
    transform: scale(1.02);
    transition: all .3s ease-in-out;
}