/* General footer style and layout*/
.footer-colour{
  background-color: #eaeaea;
  color: rgba(0, 0, 0, 0.55) !important;
}
.footer-col p{
  margin-bottom: 0.7rem;
}
.footer-nav-link{
  transition: none !important;
}
.footer-nav-link:hover{
    color: rgba(0, 0, 0, 0.7) !important;
    transition: none !important;
    transform: scale(1.01);
}

/* Back-to-top arrow*/
#back_to_top_cont{
  position:fixed;
  bottom: 10%;
  right: 3%;
  transition: all .7s ease-in-out;
}

.back_to_top{
  font-size: 400%;
  color: #008cf4;
  transition: .2s;
  border-radius: 100%; 
}

.back_to_top:hover{
  cursor: pointer ;
  color: #4064ac;
  border-radius: 100%; 
  transition: .2s;
  transform:scale(1.2)
}

/* Hides arrow when the window is at the top*/
.arrow-up-hidden{
  display: none;
  transition: all .7s ease-in-out;
}