/* Navbar layout*/
.navbar{
    height:65px;
}

.nav-padding{
    padding-left: 5.5%;
}

@media (max-width: 991px) and (min-width: 576px){
    .nav-padding{
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 576px){
    .nav-padding{
        padding-left: 20px;
        padding-right: 20px;
    }
}

/* Top and scroll styles for navbar*/
.top{
    background-color: #f8f8f8 !important;
    transition: all .5s ease-in-out !important;
}

.scroll { 
	background-color: #dce8f8 !important;
    transition: all .5s ease-in-out !important;
}

/* Brand*/
.brand{
    border: none;
}

.logo{
    max-width: 65px;
}

/* Languages*/
.languages{
    border: none;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

/* Links */
.nav-bar-link{
    font-size: larger;
    border: none;
    transition: none;
    color: rgba(0, 0, 0, 0.55) !important;
}

.nav-bar-link:hover{
    color: #008cf4 !important;
    transition: none !important;
}

/* Dropdown menu for email*/
.dropdown-item{
    padding: 0;
}

.dropdown-button{
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.55);
    border: none;
    padding: 0;
}
.dropdown-menu{
    --bs-dropdown-link-active-color: #008cf4 !important;
    --bs-dropdown-link-active-bg: #ffffff !important;
}

.dropdown-button:hover{
    color: #008cf4;
}

.drop-item{
    color: rgba(0, 0, 0, 0.55);
}

.drop-item:hover{
    background-color: #ffffff;
    color: #008cf4;
}

.dropdown-size{
    font-size:larger;
}

.dropdown-menu a:nth-child(2) svg{
    transform: scale(1.15);
    margin-left: 2px;
}

/* Offcanvas links*/
.offcanvas-hidden{
    display: none;
}

.offcanvas-logo{
    color: #008cf4;
    border: none;
    font-size:xx-large
}

.offcanvas-logo:hover{
    color: #4064ac!important;
}

.offcanvas-style{
    background-color: #ffffff !important;
}

.offcanvas-size{
    font-size:x-large;
}

.offcanvas-links{
    color: #008cf4 !important;
}

.offcanvas-links:hover{
    color: #4064ac!important;
}

.offcanvas-languages{
    color: #008cf4 !important;
}
.offcanvas-languages-faded{
    color: rgba(0, 0, 0, 0.65) !important;
}

.offcanvas-titles{
    cursor:default;
    color: rgba(0, 0, 0, 0.65) !important;
    font-size:x-large;
    font-family: "Inter-Bold", "Segoe UI";
}

.offcanvas-titles:hover{
    cursor:default;
    color: rgba(0, 0, 0, 0.65) !important;
}